const historyTracker = {
    history: [],
    historyname: [],

    addPath: (path, name, level) => {
        let local = JSON.parse(localStorage.getItem('history'));
        if (local.length > 0 && historyTracker.history.length === 0) {
            historyTracker.history = local;
            historyTracker.historyname = JSON.parse(localStorage.getItem('historyname'));
        }

        let size = historyTracker.history.length - 1;
        if (level <= size) {
            historyTracker.history.splice(level, size);
            historyTracker.historyname.splice(level, size);
        }

        historyTracker.history.push(path);
        historyTracker.historyname.push(name);
        localStorage.setItem('history', JSON.stringify(historyTracker.history));
        localStorage.setItem('historyname', JSON.stringify(historyTracker.historyname));

    },

    getHistory: () => {
        return ({ links: historyTracker.history, names: historyTracker.historyname });
    },

    cleanHistory: () => {
        try {
            historyTracker.history = [];
            historyTracker.historyname = [];
            localStorage.setItem('history', JSON.stringify(historyTracker.history));
            localStorage.setItem('historyname', JSON.stringify(historyTracker.historyname));
        } catch (e) {
            console.log(e)
        }
    }
};

export default historyTracker;